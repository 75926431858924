import { Component, computed, OnDestroy, OnInit, Signal, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { AuthorizationConcept, AuthorizationService, AuthorizationType, fromAuth } from '@iot-platform/auth';
import { AnalyticsService, LocalStorageKeys, LocalStorageService } from '@iot-platform/core';
import { fromGrids, GridManagerUserPopupComponent, GridsDbActions } from '@iot-platform/grid-engine';
import {
  CONFIGURE_GRIDS_BUTTON_CONFIG,
  EXPORT_BUTTON_CONFIG,
  FAVORITE_VIEWS_MENU_BUTTON_CONFIG,
  FavoriteViewFormComponent,
  FilterEngineMode,
  IotToolbarDefaultButton,
  IotToolbarDispatchActionType,
  IotToolbarMenuButton,
  PopupComponent,
  REFRESH_BUTTON_CONFIG,
  TOGGLE_FILTER_ENGINE_BUTTON_CONFIG,
  VariableChartDialogComponent
} from '@iot-platform/iot-platform-ui';
import {
  ChatEvent,
  CommonApiRequest,
  CommonIndexedPagination,
  FavoriteView,
  Filter,
  IotAction,
  IotToolbarEvent,
  MasterViewEngineEvent,
  TagCategory,
  ToolbarSize,
  UserAccount
} from '@iot-platform/models/common';
import { I4BGrid, I4BGridData, I4BGridOptions } from '@iot-platform/models/grid-engine';
import { Asset, AssetVariable, CommentContext, Concept, Device, DeviceEvent, DeviceVariable, Log, Site } from '@iot-platform/models/i4b';
import { fromUserPreferences, PreferencesActions } from '@iot-platform/users';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { get } from 'lodash';
import { BehaviorSubject, combineLatest, Observable, of, Subject, Subscription } from 'rxjs';
import { delay, first, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { DeviceEventsApi } from '../../+state/device-events.api';
import { EventDetailPopupComponent } from '../../../../components/event-detail-popup/event-detail-popup.component';
import { NavigationApi } from '../../../../containers/+state/navigation.api';
import { setDisableFavoriteViewButtons } from '../../../../helpers/favorite-view.helpers';

@Component({
  selector: 'iot4bos-ui-device-events-shell',
  templateUrl: './device-events-shell.component.html',
  styleUrls: ['./device-events-shell.component.scss'],
  standalone: false
})
export class DeviceEventsShellComponent implements OnInit, OnDestroy {
  analytic: AnalyticsService = new AnalyticsService('device_events_shell');
  masterViewDeviceEventsButtonList!: (IotToolbarDefaultButton | IotToolbarMenuButton)[];

  deviceEventGridsConfiguration$: Observable<{
    sortedGridsWithoutAppDefault: I4BGrid<I4BGridOptions, I4BGridData>[];
    currentGrid: I4BGrid<I4BGridOptions, I4BGridData> | undefined;
    isGridsLoading: boolean;
  }> = this.store.select(fromGrids.selectDeviceEventGridsConfiguration);

  grid$ = this.deviceEventGridsConfiguration$.pipe(map((conf) => conf.currentGrid));

  grids$ = this.deviceEventsApi.grids$;
  grids: I4BGrid<I4BGridOptions, I4BGridData>[] = [];
  grid: I4BGrid<I4BGridOptions, I4BGridData>;
  totalDeviceEvents$: Observable<number> = this.grid$.pipe(map((grid) => (grid?.data?.response?.pagination as CommonIndexedPagination)?.total | 0));
  deviceEventsLoaded$: Observable<boolean> = this.grid$.pipe(
    switchMap((grid) => {
      if (grid) {
        return this.store.select(fromGrids.getDataLoadedByGrid(grid.id as string));
      } else {
        return of(false);
      }
    })
  );
  gridSort$ = this.grid$.pipe(
    switchMap((grid) => {
      if (grid) {
        return this.store.select(fromGrids.getSortByGrid(grid.id as string));
      } else {
        return of([]);
      }
    })
  );

  hasLeft = false;

  currentUser$: Observable<UserAccount> = this.store.select(fromUserPreferences.getCurrentUser);
  user!: UserAccount;
  currentFavoriteView: FavoriteView = {};

  deviceEventFavoriteViewsConfiguration$: Observable<{
    sortedFavoriteViews: FavoriteView[];
    currentFavoriteView: FavoriteView | undefined;
    isFavoriteViewsLoading: boolean;
  }> = this.deviceEventsApi.deviceEventFavoriteViewsConfiguration$;

  currentFilters: Filter[] = [];
  currentFilters$: Observable<Filter[]> = this.deviceEventsApi.currentFilters$;
  currentFavoriteView$: Observable<FavoriteView | undefined> = this.deviceEventFavoriteViewsConfiguration$.pipe(map((conf) => conf.currentFavoriteView));

  filterEngineOpened = false;

  userPermissions: { key: string; value: boolean }[];
  selectedRowId?: string;
  destroyed$: Subject<boolean> = new Subject();

  _timerValue$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  timerValue$: Observable<number> = this._timerValue$.asObservable().pipe(delay(0));

  subscriptions: Subscription[] = [];

  canUpdateEvent: boolean;
  canReadDevice = false;
  canReadSite = false;
  canUpdateBusinessProfile: boolean;
  FilterEngineMode = FilterEngineMode;
  // Comments
  @ViewChild('sidenav', { static: true }) sidenav!: MatSidenav;
  deviceEventComments: Signal<Log[]> = this.deviceEventsApi.deviceEventComments;
  deviceEventCommentsLoading: Signal<boolean> = this.deviceEventsApi.deviceEventCommentsLoading;
  CONCEPT = Concept;
  commentsContexts!: CommentContext[];

  deviceByDeviceEvent$!: Observable<Device>;
  deviceVariableByDeviceEvent$!: Observable<DeviceVariable>;
  assetByDeviceEvent$!: Observable<Asset>;
  assetVariableByDeviceEvent$!: Observable<AssetVariable>;
  siteLoaded$!: Observable<boolean>;
  deviceLoaded$!: Observable<boolean>;
  deviceVariableLoaded$!: Observable<boolean>;
  assetLoaded$!: Observable<boolean>;
  tagsByEvent$!: Observable<TagCategory[]>;
  tagsLoaded$!: Observable<boolean>;
  totalEvents = 0;

  selectedDeviceEvent: Signal<DeviceEvent | undefined> = this.store.selectSignal(fromGrids.getSelectedItemInSelectedGrid) as Signal<DeviceEvent | undefined>;

  toolbarSize: string = ToolbarSize.SMALL;

  constructor(
    private readonly dialog: MatDialog,
    private readonly navigationApi: NavigationApi,
    private readonly store: Store,
    private readonly deviceEventsApi: DeviceEventsApi,
    private readonly authorizationService: AuthorizationService,
    private readonly storage: LocalStorageService,
    private readonly router: Router,
    private readonly translateService: TranslateService
  ) {
    this.canUpdateEvent = this.authorizationService.applyAuthorization(AuthorizationConcept.EVENT, AuthorizationType.UPDATE);
    this.canReadDevice = this.authorizationService.applyAuthorization(AuthorizationConcept.DEVICE, AuthorizationType.READ);
    this.canReadSite = this.authorizationService.applyAuthorization(AuthorizationConcept.SITE, AuthorizationType.READ);
    this.canUpdateBusinessProfile = this.authorizationService.applyAuthorization(AuthorizationConcept.BUSINESS_PROFILE, AuthorizationType.UPDATE);
    this.userPermissions = [{ key: 'canUpdateEvent', value: this.canUpdateEvent }];
    this.handleScrollPosition();
    this.initToolbarButtonList();
  }

  ngOnInit() {
    this.grids$.pipe(takeUntil(this.destroyed$)).subscribe((grids: I4BGrid<I4BGridOptions, I4BGridData>[]) => (this.grids = grids));
    this.grid$.pipe(takeUntil(this.destroyed$)).subscribe((grid: I4BGrid<I4BGridOptions, I4BGridData>) => (this.grid = grid));

    this.subscriptions.push(
      combineLatest([this.store.select(fromAuth.selectSelectedBusinessProfileForAccount), this.grids$, this.grid$]).subscribe(
        ([businessProfile, grids, grid]) => {
          if (businessProfile && grids && !grid) {
            this.selectDefaultGrid();
          }
        }
      )
    );

    combineLatest([this.currentUser$, this.currentFilters$])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([currentUser, filters]) => {
        if (currentUser && currentUser.id) {
          this.currentFilters = filters ?? [];
          setDisableFavoriteViewButtons(
            this.masterViewDeviceEventsButtonList,
            this.currentFilters,
            this.currentFavoriteView,
            this.canUpdateBusinessProfile,
            currentUser.id
          );
        }
      });

    combineLatest([this.currentUser$, this.currentFavoriteView$])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([currentUser, favoriteView]) => {
        if (currentUser && currentUser.id) {
          this.currentFavoriteView = favoriteView ?? {};
          setDisableFavoriteViewButtons(
            this.masterViewDeviceEventsButtonList,
            this.currentFilters,
            this.currentFavoriteView,
            this.canUpdateBusinessProfile,
            currentUser.id
          );
        }
      });

    this.deviceEventsLoaded$.pipe(takeUntil(this.destroyed$)).subscribe((dataLoaded: boolean) => {
      this.masterViewDeviceEventsButtonList.forEach((button) => {
        if (button.icon !== 'filter_list') {
          button.disabled = !dataLoaded;
        }
      });
    });

    this.currentUser$.pipe(takeUntil(this.destroyed$)).subscribe((user) => {
      if (user) {
        this.user = user;
        this.filterEngineOpened = get(user, 'preferences.filterEngineOpenByDefault', false);
      }
    });
  }

  loadData(): void {
    this.grid$.pipe(take(1)).subscribe((grid) => {
      if (grid?.data) {
        const pagination: CommonIndexedPagination = grid.data.response.pagination as CommonIndexedPagination;
        const request: CommonApiRequest = {
          limit: pagination.limit,
          page: 0,
          filters: this.currentFilters,
          concept: grid.masterview.toLowerCase(),
          variables: grid.gridOptions.variableNames,
          tags: grid.gridOptions.tagIds
        };
        this.deviceEventsApi.loadDeviceEvents(request);
      }
    });
  }

  initToolbarButtonList(): void {
    this.masterViewDeviceEventsButtonList = [
      new IotToolbarDefaultButton(TOGGLE_FILTER_ENGINE_BUTTON_CONFIG, 0),
      new IotToolbarDefaultButton(REFRESH_BUTTON_CONFIG, 1),
      new IotToolbarDefaultButton(EXPORT_BUTTON_CONFIG, 2),
      new IotToolbarMenuButton(FAVORITE_VIEWS_MENU_BUTTON_CONFIG, 3),
      new IotToolbarDefaultButton(CONFIGURE_GRIDS_BUTTON_CONFIG, 4)
    ];
  }

  onCreateFavoriteView() {
    this.analytic.log('toolbar_actions', 'open_add_favorite_view');
    const favoriteView: FavoriteView = {};
    favoriteView.masterView = 'device-events';
    favoriteView.concept = 'EVENT';
    favoriteView.filters = this.currentFilters;

    const dialogRef = this.dialog.open(FavoriteViewFormComponent, {
      width: '1100px',
      data: { favoriteView, canUpdateBusinessProfile: this.canUpdateBusinessProfile, grids: this.grids },
      disableClose: true
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: { grid?: I4BGrid<I4BGridOptions, I4BGridData>; favoriteView: FavoriteView }) => {
        if (!!response) {
          this.deviceEventsApi.saveFavoriteView(response);
          this.analytic.log('toolbar_actions', 'add_favorite_view');
        }
      });
  }

  onEditFavoriteView() {
    this.analytic.log('toolbar_actions', 'open_edit_favorite_view');
    const fv: FavoriteView = { ...this.currentFavoriteView };
    fv.filters = [...this.currentFilters];
    const dialogRef = this.dialog.open(FavoriteViewFormComponent, {
      width: '1100px',
      data: { favoriteView: fv, canUpdateBusinessProfile: this.canUpdateBusinessProfile, grids: this.grids },
      disableClose: true
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: { grid?: I4BGrid<I4BGridOptions, I4BGridData>; favoriteView: FavoriteView }) => {
        if (!!response) {
          this.deviceEventsApi.updateFavoriteView(response);
          this.analytic.log('toolbar_actions', 'update_favorite_view');
        }
      });
  }

  onDeleteFavoriteView() {
    this.analytic.log('toolbar_actions', 'open_delete_favorite_view');
    const dialogRef = this.dialog.open(PopupComponent, {
      width: '500px',
      disableClose: true,
      data: { type: 'delete', value: this.currentFavoriteView.name }
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: boolean) => {
        if (response) {
          this.deviceEventsApi.deleteFavoriteView(this.currentFavoriteView);
          this.clearAppliedFilters();
          this.analytic.log('toolbar_actions', 'delete_favorite_view');
        }
      });
  }

  onApplyGrid(grid: I4BGrid<I4BGridOptions, I4BGridData>) {
    if (grid) {
      this.analytic.log('toolbar_actions', 'select_grid');
      this.clearCheckedItems();
      this.selectGridAndLoadData(grid.id as string, grid.masterview, this.currentFilters);
    }
  }

  onExportData(): void {
    const openSettingsSub$: Subject<void> = new Subject<void>();

    this.grid$.pipe(first(), takeUntil(openSettingsSub$)).subscribe((grid) => {
      if (grid) {
        this.analytic.log('toolbar_actions', 'export_data', `Export data : ${(grid.data.response.pagination as CommonIndexedPagination).total} elements`);
        grid.export({
          filters: this.currentFilters,
          totalElements: (grid.data.response.pagination as CommonIndexedPagination).total
        });
      }
      openSettingsSub$.next();
      openSettingsSub$.complete();
    });
  }

  onMasterViewEngineEvent(event: MasterViewEngineEvent) {
    switch (event.type) {
      case 'open':
        this.selectItem(event.rawData.id);
        this.openEventDetail(event.rawData);
        break;
      case 'navigateToSite':
        this.openSiteDetail({ rowId: event.rawData.id, site: event.rawData.context.site });
        break;
      case 'navigateToDevice':
        this.openDeviceDetail({
          rowId: event.rawData.id,
          device: event.rawData.context.device,
          site: event.rawData.context.site
        });
        break;
      case 'navigateToAsset':
        if (!!event.rawData.context.asset.id) {
          this.openAssetDetail({
            rowId: event.rawData.id,
            asset: event.rawData.context.asset,
            site: event.rawData.context.site
          });
        }
        break;
      case 'selectionChanged':
        this.checkItems(event.rawData);
        break;
      case 'openGraph':
        this.openGraph(event.rawData);
        break;
      case 'snooze':
      case 'acknowledge':
      case 'close':
        this.onUpdateStatus(event.rawData, event.type);
        break;
      case 'bulkSnooze':
        const eventsToSnooze = event.rawData.filter((e: DeviceEvent) => e.status === 'active' && e.snoozeQuota !== 0);
        this.onBulkStatusUpdate(eventsToSnooze, 'snooze');
        break;
      case 'bulkAcknowledge':
        const eventsToAcknowledge = event.rawData.filter((e: DeviceEvent) => e.status === 'active');
        this.onBulkStatusUpdate(eventsToAcknowledge, 'acknowledge');
        break;
      case 'bulkClose':
        const eventsToClose = event.rawData.filter((e: DeviceEvent) => e.status === 'acknowledged');
        this.onBulkStatusUpdate(eventsToClose, 'close');
        break;
      case 'openComments':
        this.selectItem(event.rawData.id);
        this.onOpenComments(event.rawData);
        break;
      default:
        break;
    }
  }

  onOpenComments(deviceEvent: DeviceEvent) {
    this.deviceEventsApi.loadComments(deviceEvent);
    this.analytic.log('grid_actions', 'open_comments');
    this.commentsContexts = [
      { name: Concept.EVENT, checked: true, disabled: false },
      { name: Concept.DEVICE, checked: false, disabled: !this.canReadDevice },
      { name: Concept.SITE, checked: false, disabled: !this.canReadSite }
    ];
    this.sidenav.open();
  }

  onCommentsEvent(event: ChatEvent): void {
    switch (event.name) {
      case IotAction.ADD:
        this.onAddComment(event.value as string);
        break;
      case IotAction.EDIT:
        this.onEditComment(event.value as Log);
        break;
      case IotAction.DELETE:
        this.onDeleteComment(event.value as string);
        break;
      case IotAction.CLOSE:
        this.onCloseComments();
        break;
      default:
        break;
    }
  }

  onAddComment(comment: string) {
    this.deviceEventsApi.addComment(this.selectedDeviceEvent() as DeviceEvent, comment);
    this.analytic.log('grid_actions', 'add_comment');
  }

  onEditComment(comment: Log) {
    this.analytic.log('grid_actions', 'edit_comment');
    this.deviceEventsApi.editComment(this.selectedDeviceEvent()?.id as string, comment);
  }

  onDeleteComment(commentId: string) {
    this.analytic.log('grid_actions', 'delete_comment');
    this.deviceEventsApi.deleteComment(this.selectedDeviceEvent() as DeviceEvent, commentId);
  }

  onCloseComments() {
    this.toggleRefreshActivated(true);
    this.sidenav.close();
  }

  openEventDetail(deviceEvent: DeviceEvent): void {
    this.analytic.log('grid_actions', 'open_event_detail');
    this.deviceEventsApi.loadComments(deviceEvent);

    const detailPopup = this.dialog.open(EventDetailPopupComponent, {
      width: '1100px',
      disableClose: false,
      data: {
        event: deviceEvent,
        comments: computed(() => this.deviceEventComments().filter((log: Log) => log.concept === Concept.EVENT)),
        commentsLoading: this.deviceEventCommentsLoading,
        canUpdateEvent: this.canUpdateEvent
      }
    });

    detailPopup.componentInstance.updateStatus.subscribe((status: string) => {
      this.analytic.log('popup_actions', 'single_update_status_from_detail_popup', status);
      this.deviceEventsApi.updateStatusByDeviceEventId(deviceEvent.id, status);
    });

    detailPopup.componentInstance.navigateToSite.subscribe((site: Site) => {
      this.navigationApi.selectLeSite(site);
      this.storage.set(LocalStorageKeys.STORAGE_MV_ORIGIN_KEY, 'device-event');
      this.storage.set(LocalStorageKeys.STORAGE_SELECTED_ROW_ID, deviceEvent.id);
      detailPopup.close();
    });

    detailPopup.componentInstance.addComment.subscribe((value: string) => {
      this.analytic.log('popup_actions', 'add_comment_from_detail_popup');
      this.onAddComment(value);
    });
  }

  selectItem(itemId: string) {
    if (this.grid && itemId) {
      this.store.dispatch(GridsDbActions.selectItemInGridData({ gridId: this.grid.id as string, itemId }));
    }
  }

  checkItems(nodes: any[]) {
    if (nodes.length > 0) {
      this.toggleRefreshActivated(false);
      this.store.dispatch(
        GridsDbActions.checkItemsInGridData({
          gridId: this.grid.id as string,
          itemIds: nodes.map((node) => node.data.id)
        })
      );
    } else {
      this.toggleRefreshActivated(true);
    }
  }

  clearCheckedItems() {
    this.toggleRefreshActivated(true);
    this.store.dispatch(GridsDbActions.checkItemsInGridData({ gridId: this.grid.id as string, itemIds: [] }));
  }

  openGraph(deviceEvent: DeviceEvent) {
    this.analytic.log('grid_actions', 'open_graph');
    this.toggleRefreshActivated(false);
    this.dialog
      .open(VariableChartDialogComponent, {
        width: '990px',
        data: {
          variables: [
            {
              ...deviceEvent.context.deviceVariable,
              device: { id: deviceEvent.context.device.id, name: deviceEvent.context.device.name }
            }
          ],
          variableType: 'deviceVariable'
        }
      })
      .afterClosed()
      .subscribe((_) => this.toggleRefreshActivated(true));
  }

  transformEventsInArray(events): DeviceEvent[] {
    if (events.length > 0) {
      return events;
    }
    const eventAsArray = [];
    eventAsArray.push(events);
    return eventAsArray;
  }

  onUpdateStatus(deviceEvent: DeviceEvent, status: string) {
    this.analytic.log('grid_actions', 'single_update_status', status);
    this.deviceEventsApi.updateStatusByDeviceEventId(deviceEvent.id, status);
  }

  onBulkStatusUpdate(deviceEvents: DeviceEvent[], status: string) {
    this.analytic.log('grid_actions', 'bulk_update_status', status);
    this.dialog
      .open(PopupComponent, {
        data: {
          type: 'confirm',
          value: this.translateService.instant('EVENTS.BULK_STATUS_UPDATE.CONFIRMATION_MESSAGES.' + status.toUpperCase(), { total: deviceEvents.length })
        },
        disableClose: true,
        width: '600px'
      })
      .afterClosed()
      .subscribe((confirmation: boolean) => {
        if (confirmation && deviceEvents.length) {
          this.analytic.log('cta_actions', 'bulk_update_status', `${status} : ${deviceEvents.length}`);
          this.deviceEventsApi.bulkUpdateStatusByDeviceEventIds(
            deviceEvents.map((event) => event.id),
            status
          );
        }
      });
  }

  openSiteDetail({ rowId, site }: { rowId: string; site: Site }) {
    if (site.type === 'stock') {
      this.navigationApi.selectLeStock(site, {
        page: 0,
        limit: 25,
        filters: [{ criteriaKey: 'siteId', value: site.id }]
      });
    } else {
      this.navigationApi.selectLeSite(site);
    }
    this.storage.set(LocalStorageKeys.STORAGE_MV_ORIGIN_KEY, 'device-event');
    this.storage.set(LocalStorageKeys.STORAGE_SELECTED_ROW_ID, rowId);
  }

  openDeviceDetail(itemToNavigate: { rowId: string; device: Device; site: Site }) {
    const selectedDevice: Device = { ...itemToNavigate.device, site: itemToNavigate.site };
    this.navigationApi.selectDeviceAvecLeSite(selectedDevice);
    this.storage.set(LocalStorageKeys.STORAGE_MV_ORIGIN_KEY, 'device-event');
    this.storage.set(LocalStorageKeys.STORAGE_SELECTED_ROW_ID, itemToNavigate.rowId);
  }

  openAssetDetail(itemToNavigate: { rowId: string; asset: Asset; site: Site }) {
    const selectedAsset: Asset = { ...itemToNavigate.asset, site: itemToNavigate.site };
    this.navigationApi.selectAssetAvecLeSite(selectedAsset);
    this.storage.set(LocalStorageKeys.STORAGE_MV_ORIGIN_KEY, 'device-event');
    this.storage.set(LocalStorageKeys.STORAGE_SELECTED_ROW_ID, itemToNavigate.rowId);
  }

  openGridSettings() {
    this.analytic.log('toolbar_actions', 'open_grid_settings');
    this.grid$
      .pipe(
        take(1),
        switchMap((gridToUpdate) => {
          this.toggleRefreshActivated(false);
          return this.dialog
            .open(GridManagerUserPopupComponent, {
              width: '1400px',
              disableClose: true,
              data: { grid: { ...gridToUpdate, data: null } }
            })
            .afterClosed();
        })
      )
      .subscribe((value: { action: string; grid: any }) => {
        this.toggleRefreshActivated(true);
        if (!!value) {
          if (value.action === 'DELETE') {
            this.analytic.log('grid_actions', 'delete_grid');
            this.store.dispatch(
              GridsDbActions.removeGrid({
                toRemove: {
                  ...value.grid,
                  gridOptions: { ...value.grid.gridOptions, filters: this.currentFilters }
                }
              })
            );
          }
          if (value.action === 'UPDATE') {
            this.analytic.log('grid_actions', 'update_grid');
            this.store.dispatch(
              GridsDbActions.updateGrid({
                toUpdate: {
                  ...value.grid,
                  gridOptions: { ...value.grid.gridOptions, filters: this.currentFilters }
                }
              })
            );
          }
          if (value.action === 'ADD') {
            this.analytic.log('grid_actions', 'add_grid');
            this.store.dispatch(
              GridsDbActions.addGrid({
                toAdd: {
                  ...value.grid,
                  gridOptions: { ...value.grid.gridOptions, filters: this.currentFilters }
                }
              })
            );
          }
        }
      });
  }

  toggleRefreshActivated(refreshActivated: boolean): void {
    this.store.dispatch(GridsDbActions.toggleRefreshActivated({ refreshActivated }));
  }

  onApplyFavoriteView(favoriteView: FavoriteView) {
    this.deviceEventsApi.setCurrentFavoriteView(favoriteView);
    if (favoriteView) {
      this.analytic.log('toolbar_actions', 'select_favorite_view');
      this.selectGridAndLoadData(favoriteView.gridId as string, favoriteView.masterView as string, this.currentFilters);
    } else {
      this.selectDefaultGrid();
      this.analytic.log('toolbar_actions', 'reset_to_default_favorite_view');
    }
  }

  onApplyFilters(filters: Filter[]) {
    if (filters.length === 0) {
      this.onApplyFavoriteView(null);
    } else {
      this.deviceEventsApi.setCurrentFilters(filters);
      const gaLog = Object.entries(filters)
        .map(([_, value]) => `${value['criteriaKey']}`)
        .join(', ');
      this.analytic.log('filters_actions', 'apply_filters', `Applied filters : ${gaLog}`);
    }
    this.loadData();
  }

  onToolbarEvent(event: IotToolbarEvent): void {
    {
      switch (event.type) {
        case IotToolbarDispatchActionType.REFRESH_PAGE:
          this.onRefreshClicked();
          break;
        case IotToolbarDispatchActionType.EXPORT_DATA:
          this.onExportData();
          break;
        case IotToolbarDispatchActionType.MANAGE_GRID_SETTINGS:
          this.openGridSettings();
          break;
        case IotToolbarDispatchActionType.APPLY_FAVORITE_VIEW:
          this.onApplyFavoriteView(event.options);
          break;
        case IotToolbarDispatchActionType.APPLY_GRID:
          this.onApplyGrid(event.options.grid);
          break;
        case IotToolbarDispatchActionType.TOGGLE_FILTER_ENGINE:
          this.onShowFilter();
          break;
        case IotToolbarDispatchActionType.CREATE_FAVORITE_VIEW:
          this.onCreateFavoriteView();
          break;
        case IotToolbarDispatchActionType.EDIT_FAVORITE_VIEW:
          this.onEditFavoriteView();
          break;
        case IotToolbarDispatchActionType.DELETE_FAVORITE_VIEW:
          this.onDeleteFavoriteView();
          break;
        default:
          break;
      }
    }
  }

  onShowFilter(): void {
    this.analytic.log('toolbar_actions', this.filterEngineOpened ? 'close_filter_engine' : 'open_filter_engine');
    if (this.user) {
      this.filterEngineOpened = !this.filterEngineOpened;
      this.user.preferences = { ...this.user.preferences, filterEngineOpenByDefault: this.filterEngineOpened };
      this.store.dispatch(
        PreferencesActions.saveUserPreferences({
          user: this.user,
          preferences: this.user.preferences
        })
      );
    }
  }

  onRefreshClicked() {
    this.analytic.log('toolbar_actions', 'refresh');
    this.loadData();
  }

  clearAppliedFilters() {
    this.analytic.log('filters_actions', 'clear_filters');
    this.deviceEventsApi.setCurrentFavoriteView(null);
    this.selectDefaultGrid();
  }

  ngOnDestroy() {
    this.hasLeft = true;
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  private selectGridAndLoadData(gridId: string, masterview: string, filters: Filter[]) {
    this.store.dispatch(GridsDbActions.selectGridAndLoadData({ gridId, masterview, filters }));
  }

  private selectDefaultGrid(): void {
    this.selectGridAndLoadData('default', 'device-events', this.currentFilters);
  }

  private handleScrollPosition() {
    const keepScrollPosition = this.router.getCurrentNavigation()?.extras.state?.keepScrollPosition;
    if (keepScrollPosition) {
      this.selectedRowId = this.storage.get(LocalStorageKeys.STORAGE_SELECTED_ROW_ID);
    }
  }
}
