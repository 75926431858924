import { CommonModule, JsonPipe, NgIf } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule, Routes } from '@angular/router';
import { CardLoaderModule, DeviceDetailsModule, DeviceInfoStatusModule, DeviceInfoToolbarComponent, IotPlatformUiModule } from '@iot-platform/iot-platform-ui';
import { DateFormatPipe, InfoDisplayPipe, UserNamePipe } from '@iot-platform/pipes';
import { SharedModule } from '@iot-platform/shared';
import { FavoriteViewsModule } from '@iot-platform/shared/components';
import { TranslateModule } from '@ngx-translate/core';
import { DeviceDetailPopupComponent } from './components/device-detail-popup/device-detail-popup.component';
import { DeviceInfoComponent } from './components/device-info/device-info.component';
import { DeviceMoveToFormComponent } from './components/device-move-to-form/device-move-to-form.component';
import { DeviceOverviewComponent } from './containers/device-overview/device-overview.component';
import { DeviceOverviewModule } from './containers/device-overview/device-overview.module';
import { devicesShellMasterViewProvider } from './containers/devices-shell/devices-shell-master-view.provider';

const devicesRoutes: Routes = [
  {
    path: '',
    loadComponent: () => import('@iot-platform/feature/master-view').then((m) => m.FeatureMasterViewComponent),
    providers: [devicesShellMasterViewProvider],
    pathMatch: 'full'
  },
  { path: ':deviceId', component: DeviceOverviewComponent, children: [{ path: '', component: DeviceInfoComponent }] }
];

@NgModule({
  imports: [
    RouterModule.forChild(devicesRoutes),
    DeviceOverviewModule,
    FavoriteViewsModule,
    DeviceInfoToolbarComponent,
    TranslateModule,
    DeviceInfoStatusModule,
    DeviceDetailsModule,
    CardLoaderModule,
    FlexLayoutModule,
    JsonPipe,
    MatButtonModule,
    MatIconModule,
    NgIf,
    CommonModule,
    SharedModule,
    IotPlatformUiModule,
    UserNamePipe,
    InfoDisplayPipe,
    DateFormatPipe
  ],
  exports: [RouterModule],
  providers: [UserNamePipe, InfoDisplayPipe, DateFormatPipe],
  declarations: [DeviceMoveToFormComponent, DeviceDetailPopupComponent]
})
export class DevicesModule {}
