import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

import { TranslateModule } from '@ngx-translate/core';
import { MasterViewEngineDirectiveModule } from '../../master-view-engine/master-view-table/master-view-engine-directive.module';
import { BasicHeaderComponent } from './basic-header/basic-header.component';
import { ButtonHeaderComponent } from './button-header/button-header.component';
import { CheckboxHeaderComponent } from './checkbox-header/checkbox-header.component';
import { IconAndTextHeaderComponent } from './icon-and-text-header/icon-and-text-header.component';
import { IconHeaderComponent } from './icon-header/icon-header.component';
import { MasterViewHeaderContainerComponent } from './master-view-header-container.component';

@NgModule({
  imports: [CommonModule, MasterViewEngineDirectiveModule, TranslateModule, FlexLayoutModule, MatCheckboxModule, MatTooltipModule, MatIconModule],
  declarations: [
    MasterViewHeaderContainerComponent,
    ButtonHeaderComponent,
    BasicHeaderComponent,
    CheckboxHeaderComponent,
    IconAndTextHeaderComponent,
    IconHeaderComponent
  ],
  exports: [MasterViewHeaderContainerComponent]
})
export class MasterViewHeaderModule {}
