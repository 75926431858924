<div class="ag-theme-material grid-wrapper">
  <ag-grid-angular
    (firstDataRendered)="onFirstDataRendered()"
    (gridReady)="onGridReady($event)"
    (newColumnsLoaded)="onGridColumnsChanged()"
    (rowSelected)="rowSelected.next($event)"
    (sortChanged)="onSortChanged($event)"
    [accentedSort]="true"
    [colResizeDefault]="'shift'"
    [columnDefs]="columnDefs()"
    [gridOptions]="gridOptions"
    [navigateToNextCell]="navigateToNextCell"
    [navigateToNextHeader]="navigateToNextHeader"
    [rowData]="rowData()"
    [tabToNextCell]="tabToNextCell"
    [tabToNextHeader]="tabToNextHeader"
    rowHeight="38"
    style="flex: 1"
  />
  @if (displayPagination()) {
    <grid-engine-paginator (pageChange)="onPageChange($event)" [pagination]="pagination()" />
  }
</div>
<ng-template #exportViewRef />
