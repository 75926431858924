import { NgTemplateOutlet } from '@angular/common';
import { Component, input, output } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { SectionHeaderAction } from './section-header-action.model';
import { SectionHeaderEvent } from './section-header-event.model';

@Component({
  selector: 'iot-platform-ui-section-header',
  imports: [FlexLayoutModule, MatIcon, TranslateModule, MatTooltip, MatButtonModule, NgTemplateOutlet],
  templateUrl: './section-header.component.html',
  styleUrl: './section-header.component.scss'
})
export class SectionHeaderComponent {
  styles = input<any>();
  title = input<string>();
  icon = input<string>();
  svgIcon = input<string>();
  cssClassName = input<string>();
  actions = input<SectionHeaderAction[]>([]);
  showLine = input<boolean>(true);

  dispatchEvent = output<SectionHeaderEvent>();

  onDispatchEvent(button: SectionHeaderAction): void {
    this.dispatchEvent.emit({
      type: button.action
    });
  }
}
