import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MasterViewEngineEvent } from '@iot-platform/models/common';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'i4b-table-engine-threshold-icon-cell',
    templateUrl: './threshold-icon-cell.component.html',
    styleUrls: ['./threshold-icon-cell.component.scss'],
    standalone: false
})
export class ThresholdIconCellComponent implements OnInit {
  @Input() data: any;
  @Input() rawData: any;
  @Input() columnFormat: any;
  @Input() cellOptions: any;
  @Input() cellInfo: any;

  @Output() dispatchEvent: EventEmitter<MasterViewEngineEvent> = new EventEmitter<MasterViewEngineEvent>();

  tooltip: string;
  hasThreshold = false;
  maxThresholds = 5;

  constructor(private translateService: TranslateService) {}

  ngOnInit() {
    this.tooltip = this.translateService.instant(`ASSETS.VARIABLE.TOOLTIPS.THRESHOLDS`);
    if (this.rawData.thresholds) {
      this.hasThreshold = !!this.rawData.thresholds.operator && !!this.rawData.thresholds.values.length;
    } else {
      for (let i = 1; i <= this.maxThresholds; i++) {
        this.hasThreshold = this.hasThreshold || !!this.rawData['threshold' + i];
      }
    }
  }
}
