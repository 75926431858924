import { Component, Input, Output } from '@angular/core';

@Component({
    selector: 'i4b-table-engine-boolean-cell',
    templateUrl: './boolean-cell.component.html',
    styleUrls: ['./boolean-cell.component.scss'],
    standalone: false
})
export class BooleanCellComponent {
  @Input() data: any;
  @Input() rawData: any;
  @Input() columnFormat: any;
  @Input() cellOptions: any;

  @Output() dispatchEvent;

  getDataToDisplay(): string {
    switch (this.cellOptions?.mode) {
      case 'booleanAsText':
        return this.data ? this.cellOptions.dataToDisplayIfTrue : this.cellOptions.dataToDisplayIfFalse;

      case 'dataComparison':
        return this.data === this.cellOptions.comparisonKey ? this.cellOptions.dataToDisplayIfTrue : this.cellOptions.dataToDisplayIfFalse;

      default:
        return null;
    }
  }
}
