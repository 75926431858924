import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MasterViewEngineEvent } from '@iot-platform/models/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'i4b-table-engine-button-cell',
    imports: [MatButtonModule, MatIconModule, MatTooltipModule, TranslateModule, FlexLayoutModule],
    template: `
    @if (cellOptions?.svgIcon) {
      <button
        class="button-cell"
        mat-icon-button
        fxLayout="row"
        fxLayoutAlign="center center"
        [matTooltipDisabled]="cellOptions?.tooltipDisabled"
        [matTooltip]="cellOptions?.tooltip || '' | translate"
        matTooltipPosition="above"
        matTooltipClass="regular-tooltip"
        (click)="$event.preventDefault(); $event.stopPropagation(); onClick()"
      >
        @if (cellOptions?.svgIcon) {
          <mat-icon [svgIcon]="cellOptions?.svgIcon"></mat-icon>
        }
      </button>
    }
    @if (!cellOptions?.svgIcon && (cellOptions?.label || data)) {
      <button
        class="button-cell"
        mat-stroked-button
        fxLayout="row"
        fxLayoutAlign="center center"
        [matTooltipDisabled]="cellOptions?.tooltipDisabled"
        [matTooltip]="cellOptions?.tooltip || data || '' | translate"
        matTooltipPosition="above"
        matTooltipClass="regular-tooltip"
        (click)="$event.preventDefault(); $event.stopPropagation(); onClick()"
      >
        @if (cellOptions?.label || data) {
          <span class="label" [style.width]="(cellOptions?.labelWidth || 5) + 'rem'">{{ cellOptions?.label || data | translate }}</span>
        }
      </button>
    }
    @if (!cellOptions?.svgIcon && !cellOptions?.label && !data) {
      <span>-</span>
    }
  `,
    styles: [
        `
      .button-cell {
        height: 30px;
        padding: 0 0.5rem;
      }

      .label {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: block;
        font-size: 0.8rem;
      }
    `
    ]
})
export class ButtonCellComponent {
  @Input() data: any;
  @Input() rawData: any;
  @Input() clickEvent!: MasterViewEngineEvent;
  @Input() cellOptions!: {
    tooltip?: string;
    svgIcon?: string;
    label?: string;
    labelWidth?: number;
    tooltipDisabled?: boolean;
  };

  @Output() dispatchEvent: EventEmitter<MasterViewEngineEvent> = new EventEmitter<MasterViewEngineEvent>();

  onClick(): void {
    this.dispatchEvent.emit({
      type: this.clickEvent.type,
      options: this.cellOptions,
      rawData: this.rawData
    });
  }
}
