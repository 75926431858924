import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ReleaseNotesService } from '@iot-platform/iot-platform-ui';
import { SortUtil } from '@iot-platform/iot-platform-utils';
import { UserPreferencesService } from '@iot-platform/users';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import {
  assetEventsByAssetColumnSettings,
  assetEventsByAssetMetadataSettings,
  assetEventsBySiteColumnSettings,
  assetEventsBySiteMetadataSettings,
  assetEventsByTopicColumnSettings,
  assetEventsByTopicMetadataSettings,
  assetVariablesColumnSettings,
  assetVariablesConfigurationPopupColumnSettings,
  assetVariablesConfigurationPopupMetadataSettings,
  assetVariablesFollowedPopupColumnSettings,
  assetVariablesFollowedPopupMetadataSettings,
  assetVariablesMetadataSettings,
  calendarsColumnSettings,
  calendarsMetadataSettings,
  deviceCallLogsColumnSettings,
  deviceCallLogsMetadataSettings,
  deviceEventsByDeviceColumnSettings,
  deviceEventsByDeviceMetadataSettings,
  deviceEventsBySiteColumnSettings,
  deviceEventsBySiteMetadataSettings,
  deviceEventsByTopicColumnSettings,
  deviceEventsByTopicMetadataSettings,
  deviceVariablesColumnSettings,
  deviceVariablesConfigurationPopupColumnSettings,
  deviceVariablesConfigurationPopupMetadataSettings,
  deviceVariablesMetadataSettings,
  escalationProtocolsColumnSettings,
  escalationProtocolsMetadataSettings,
  productCatalogsColumnSettings,
  productCatalogsMetadataSettings,
  schedulerAvailableDevicesColumnSettings,
  schedulerAvailableDevicesMetadataSettings,
  schedulersColumnSettings,
  schedulerSelectedDevicesColumnSettings,
  schedulerSelectedDevicesMetadataSettings,
  schedulersMetadataSettings,
  teamPlanningsColumnSettings,
  teamPlanningsMetadataSettings,
  topicsColumnSettings,
  topicsMetadataSettings,
  usersColumnSettings,
  usersMetadataSettings
} from '../mv-files';

@Component({
    selector: 'i4b-table-engine-admin-settings-popup',
    templateUrl: './admin-settings-popup.component.html',
    styleUrls: ['./admin-settings-popup.component.scss'],
    standalone: false
})
export class AdminSettingsPopupComponent implements OnInit {
  version$ = this.releaseNotesService.getCurrentVersion();
  mvSettingsForm: UntypedFormGroup;
  concepts = [
    'assetVariables',
    'deviceVariables',
    'deviceCallLogs',
    'assetEventsBySite',
    'assetEventsByTopic',
    'assetEventsByAsset',
    'deviceEventsBySite',
    'deviceEventsByTopic',
    'deviceEventsByDevice',
    'deviceVariablesConfigurationPopup',
    'assetVariablesConfigurationPopup',
    'assetVariablesFollowedPopup',
    'schedulers',
    'schedulerSelectedDevices',
    'schedulerAvailableDevices',
    'topics',
    'calendars',
    'teamPlannings',
    'escalationProtocols',
    'productCatalogs',
    'users'
  ];
  selectedSettingName: string;
  columns = [];
  allColumns = {
    assetEventsBySite: assetEventsBySiteColumnSettings,
    assetEventsByTopic: assetEventsByTopicColumnSettings,
    assetEventsByAsset: assetEventsByAssetColumnSettings,
    deviceEventsBySite: deviceEventsBySiteColumnSettings,
    deviceEventsByTopic: deviceEventsByTopicColumnSettings,
    deviceEventsByDevice: deviceEventsByDeviceColumnSettings,
    assetVariables: assetVariablesColumnSettings,
    deviceVariables: deviceVariablesColumnSettings,
    deviceCallLogs: deviceCallLogsColumnSettings,
    assetVariablesFollowedPopup: assetVariablesFollowedPopupColumnSettings,
    deviceVariablesConfigurationPopup: deviceVariablesConfigurationPopupColumnSettings,
    assetVariablesConfigurationPopup: assetVariablesConfigurationPopupColumnSettings,
    schedulers: schedulersColumnSettings,
    schedulerSelectedDevices: schedulerSelectedDevicesColumnSettings,
    schedulerAvailableDevices: schedulerAvailableDevicesColumnSettings,
    productCatalogs: productCatalogsColumnSettings,
    calendars: calendarsColumnSettings,
    teamPlannings: teamPlanningsColumnSettings,
    users: usersColumnSettings,
    escalationProtocols: escalationProtocolsColumnSettings,
    topics: topicsColumnSettings
  };

  columnsWithMetadata = {
    deviceEventsBySite: deviceEventsBySiteMetadataSettings,
    deviceEventsByTopic: deviceEventsByTopicMetadataSettings,
    deviceEventsByDevice: deviceEventsByDeviceMetadataSettings,
    assetEventsBySite: assetEventsBySiteMetadataSettings,
    assetEventsByTopic: assetEventsByTopicMetadataSettings,
    assetEventsByAsset: assetEventsByAssetMetadataSettings,
    deviceVariables: deviceVariablesMetadataSettings,
    deviceCallLogs: deviceCallLogsMetadataSettings,
    deviceVariablesConfigurationPopup: deviceVariablesConfigurationPopupMetadataSettings,
    assetVariablesConfigurationPopup: assetVariablesConfigurationPopupMetadataSettings,
    assetVariablesFollowedPopup: assetVariablesFollowedPopupMetadataSettings,
    assetVariables: assetVariablesMetadataSettings,
    schedulers: schedulersMetadataSettings,
    schedulerSelectedDevices: schedulerSelectedDevicesMetadataSettings,
    schedulerAvailableDevices: schedulerAvailableDevicesMetadataSettings,
    productCatalogs: productCatalogsMetadataSettings,
    calendars: calendarsMetadataSettings,
    teamPlannings: teamPlanningsMetadataSettings,
    users: usersMetadataSettings,
    escalationProtocols: escalationProtocolsMetadataSettings,
    topics: topicsMetadataSettings
  };

  availableColumns = [];
  selectedColumns = [];

  isLoading = false;
  changesMade = false;

  constructor(
    private dialogRef: MatDialogRef<AdminSettingsPopupComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      isBusinessProfileTopLevel: boolean;
      isUserAdmin: boolean;
    },
    private userPreferencesService: UserPreferencesService,
    private releaseNotesService: ReleaseNotesService
  ) {
    this.data.isUserAdmin = true;
    this.data.isBusinessProfileTopLevel = true;
  }

  ngOnInit(): void {
    this.initForm();
  }

  public initColumns(setting) {
    this.isLoading = true;
    this.selectedSettingName = setting.value;
    this.userPreferencesService
      .loadSpecificDefaultSettings(this.selectedSettingName)
      .pipe(
        switchMap((settings) => {
          if (!settings) {
            this.columnsWithMetadata[this.selectedSettingName] = {
              ...this.columnsWithMetadata[this.selectedSettingName],
              masterViewTable: {
                ...this.columnsWithMetadata[this.selectedSettingName].masterViewTable,
                bluePrint: {
                  ...this.columnsWithMetadata[this.selectedSettingName].masterViewTable.bluePrint,
                  columns: this.allColumns[this.selectedSettingName]
                }
              }
            };
            return this.userPreferencesService.saveDefaultSettings(this.selectedSettingName, this.columnsWithMetadata[this.selectedSettingName]);
          } else {
            return of(settings);
          }
        })
      )
      .subscribe((settings: any) => {
        this.isLoading = false;
        this.changesMade = false;
        if (settings) {
          this.selectedColumns = settings.masterViewTable.bluePrint.columns.filter((c) => c.default).sort(SortUtil.sortByOrder);
          this.availableColumns = settings.masterViewTable.bluePrint.columns.filter((c) => !c.default).sort(SortUtil.sortByOrder);
        }
      });
  }

  drop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      this.changesMade = true;
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      this.changesMade = true;
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    const totalColumns = this.selectedColumns.length;
    const currentTotalWidth = this.getTotalWidth();
    const toDispatch = 100 - currentTotalWidth;
    const toAddByColumn = toDispatch / totalColumns;

    const defaultCols = [
      ...this.selectedColumns.map((column, index) => ({
        ...column,
        order: index,
        default: true,
        width: toDispatch > 0 ? parseInt(column.width.substr(0, column.width.length - 1), 10) + toAddByColumn + '%' : column.width
      }))
    ];

    const remainingCols = [
      ...this.availableColumns.map((column) => ({
        ...column,
        default: false,
        width: toDispatch > 0 ? parseInt(column.width.substr(0, column.width.length - 1), 10) + toAddByColumn + '%' : column.width
      }))
    ];
    const cols = [];
    defaultCols.forEach((col) => cols.push(col));
    remainingCols.forEach((col) => cols.push(col));

    this.columnsWithMetadata[this.selectedSettingName] = {
      ...this.columnsWithMetadata[this.selectedSettingName],
      masterViewTable: {
        ...this.columnsWithMetadata[this.selectedSettingName].masterViewTable,
        bluePrint: {
          ...this.columnsWithMetadata[this.selectedSettingName].masterViewTable.bluePrint,
          columns: cols
        }
      }
    };

    this.isLoading = true;
    this.userPreferencesService.saveDefaultSettings(this.selectedSettingName, this.columnsWithMetadata[this.selectedSettingName]).subscribe(() => {
      this.isLoading = false;
      this.changesMade = false;
    });
  }

  clearAll() {
    this.isLoading = true;
    this.userPreferencesService.resetAllMySettings().subscribe();
    this.userPreferencesService
      .resetAllDefaultSettings()
      .pipe(
        switchMap((_) => {
          this.concepts.forEach((concept) => {
            this.columnsWithMetadata[concept] = {
              ...this.columnsWithMetadata[concept],
              masterViewTable: {
                ...this.columnsWithMetadata[concept].masterViewTable,
                bluePrint: {
                  ...this.columnsWithMetadata[concept].masterViewTable.bluePrint,
                  columns: this.allColumns[concept]
                }
              }
            };
          });
          return this.userPreferencesService.saveAllDefaultSettings(this.columnsWithMetadata);
        })
      )
      .subscribe(() => {
        this.isLoading = false;
        this.changesMade = false;
        this.displayDefaultColumns();
      });
  }

  restoreDefaultSettings() {
    this.displayDefaultColumns();
    this.save();
  }

  displayDefaultColumns(): void {
    this.selectedColumns = [];
    this.availableColumns = [];
    this.selectedColumns = this.allColumns[this.selectedSettingName].filter((column) => column.default).sort(SortUtil.sortByOrder);
    this.availableColumns = this.allColumns[this.selectedSettingName].filter((column) => !column.default).sort(SortUtil.sortByOrder);
  }

  changeWidth(event: Event, column) {
    column.width = event.target['value'] + '%';
  }

  getTotalWidth() {
    const totalWidth = this.selectedColumns.reduce(
      (previousValue, current) => previousValue + parseInt(current.width?.substr(0, current.width.length - 1), 10),
      0
    );
    return totalWidth;
  }

  private initForm() {
    this.mvSettingsForm = new UntypedFormGroup({});
  }
}
