import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TagCategory } from '@iot-platform/models/common';

@Component({
    selector: 'iot4bos-ui-backoffice-business-profile-tags',
    templateUrl: './business-profile-tags.component.html',
    styleUrls: ['./business-profile-tags.component.scss'],
    standalone: false
})
export class BusinessProfileTagsComponent {
  @Input() canUpdateBusinessProfile: boolean;
  @Input() tags: TagCategory[] = [];
  @Input() tagsPendingStatus = false;

  @Output() addTagsToProfile: EventEmitter<string> = new EventEmitter<string>();

  menuItems = [
    { concept: 'site', translationKey: 'MANAGE_TAGS_FORM.SITE' },
    { concept: 'asset', translationKey: 'MANAGE_TAGS_FORM.ASSET' },
    { concept: 'device', translationKey: 'MANAGE_TAGS_FORM.DEVICE' },
    { concept: 'event', translationKey: 'MANAGE_TAGS_FORM.EVENT' }
  ];

  conceptList = ['site', 'asset', 'device', 'event'];

  getTagsTotal(tags: TagCategory[]) {
    return tags.reduce((acc, value) => (acc = acc + value.labels.length), 0);
  }

  getTagCategoriesByConcept(concept: string): TagCategory[] {
    return this.tags.filter((t) => t.concept.toLowerCase() === concept);
  }

  onAddTagsToProfile(concept: string) {
    this.addTagsToProfile.emit(concept);
  }
}
