import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DateIntervalUtils } from '@iot-platform/iot-platform-utils';
import { MasterViewEngineEvent } from '@iot-platform/models/common';
import * as moment from 'moment';
import { Moment } from 'moment-timezone';

@Component({
    selector: 'i4b-table-engine-date-cell',
    templateUrl: './date-cell.component.html',
    styleUrls: ['./date-cell.component.scss'],
    standalone: false
})
export class DateCellComponent implements OnInit {
  @Input() data: any;
  @Input() rawData: any;
  @Input() cellOptions: any;
  @Input() columnFormat: any;

  @Input() clickEvent: any;
  @Output() dispatchEvent: EventEmitter<MasterViewEngineEvent> = new EventEmitter<MasterViewEngineEvent>();

  dateFormat: string;
  isRecentDay: boolean;
  isRecentHour: boolean;
  isFutureDay: boolean;
  isNextDay: boolean;
  removeTimezone: boolean;

  ngOnInit() {
    this.dateFormat = this.getFormat();
    this.removeTimezone = this.initTimezoneRemoval();

    this.processDateIntervals(moment(), this.data);
  }

  onClick(event: MouseEvent): void {
    event.stopPropagation();
    if (this.clickEvent && this.clickEvent.type) {
      this.dispatchEvent.emit({ type: this.clickEvent.type, options: this.clickEvent.options, rawData: this.rawData });
    }
  }

  getFormat() {
    return this.cellOptions?.columnFormat ?? '';
  }

  initTimezoneRemoval() {
    return this.cellOptions?.removeTimezone === true;
  }

  processDateIntervals(now: Moment, date: string): void {
    this.isRecentDay = DateIntervalUtils.isDateInThePastDay(now, date);
    this.isRecentHour = DateIntervalUtils.isDateInThePastHour(now, date);
    this.isNextDay = DateIntervalUtils.isDateInTheNextDay(now, date);
    this.isFutureDay = DateIntervalUtils.isDateInTheFutureDays(now, date);
  }
}
