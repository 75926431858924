import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MasterViewEngineEvent } from '@iot-platform/models/common';

@Component({
    selector: 'i4b-table-engine-basic-link-cell',
    templateUrl: './basic-link-cell.component.html',
    styleUrls: ['./basic-link-cell.component.scss'],
    standalone: false
})
export class BasicLinkCellComponent implements OnInit {
  @Input() data: any;
  @Input() rawData: any;
  @Input() columnFormat: any;
  @Input() elementId: string;
  @Input() clickEvent: any;
  @Input() cellOptions: any;

  @Output() dispatchEvent: EventEmitter<MasterViewEngineEvent> = new EventEmitter<MasterViewEngineEvent>();

  maxLength = 40;
  dataType: string;

  ngOnInit() {
    this.dataType = typeof this.data;
  }

  onDispatchEvent(event: MouseEvent): void {
    event.stopPropagation();
    this.dispatchEvent.emit({ type: this.clickEvent.type, options: this.clickEvent.options, rawData: this.rawData });
  }
}
