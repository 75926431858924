import { Component, Input, Output } from '@angular/core';

@Component({
    selector: 'i4b-table-engine-cyclical-call-fault-cell',
    templateUrl: './cyclical-call-fault-cell.component.html',
    styleUrls: ['./cyclical-call-fault-cell.component.scss'],
    standalone: false
})
export class CyclicalCallFaultCellComponent {
  @Input() data: any;
  @Input() rawData: any;
  @Input() columnFormat: any;
  @Input() cellOptions: any;
  @Input() cellInfo: any;

  @Output() dispatchEvent;
}
