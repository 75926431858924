import { Component, Input, OnInit, Output } from '@angular/core';
import { ProductCellService } from './product-cell.service';

@Component({
    selector: 'i4b-table-engine-product-cell',
    templateUrl: './product-cell.component.html',
    styleUrls: ['./product-cell.component.scss'],
    standalone: false
})
export class ProductCellComponent implements OnInit {
  @Input() data: any;
  @Input() rawData: any;
  @Input() columnFormat: any;
  @Input() cellOptions: any;
  @Input() cellInfo: any;

  @Output() dispatchEvent;

  isDataLoaded = false;
  dataToDisplay;

  constructor(private productCellService: ProductCellService) {}

  ngOnInit() {
    this.productCellService.getProductById(this.data).subscribe((product) => {
      this.isDataLoaded = true;
      if (product) {
        this.dataToDisplay = product[this.cellOptions.display];
      }
    });
  }
}
