import { Injectable, Signal, computed, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthFacade, AuthorizationConcept, AuthorizationService, AuthorizationType } from '@iot-platform/auth';
import { AssetGroup, AssetGroupCreate } from '@iot-platform/models/i4b';
import { AssetGroupsActions } from '../actions';
import { assetGroupsFeature } from '../reducers/asset-groups.reducer';
import { BusinessProfile } from '@iot-platform/models/common';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AssetGroupsFacade {
  private readonly store: Store = inject(Store);
  private readonly authFacade: AuthFacade = inject(AuthFacade);
  private readonly authorizationService: AuthorizationService = inject(AuthorizationService);
  private readonly router: Router = inject(Router);

  selectedAssetGroup: Signal<AssetGroup | null> = this.store.selectSignal(assetGroupsFeature.selectSelectedAssetGroup);
  selectedBusinessProfile: Signal<BusinessProfile> = this.authFacade.selectedBusinessProfile;

  canUpdate: Signal<boolean> = computed(() => {
    this.authFacade.privileges();
    return this.authorizationService.applyAuthorization(AuthorizationConcept.ASSET, AuthorizationType.UPDATE);
  });

  canDelete: Signal<boolean> = computed(() => {
    this.authFacade.privileges();
    return this.authorizationService.applyAuthorization(AuthorizationConcept.ASSET, AuthorizationType.DELETE);
  });

  getAssetGroup(assetGroupId: string): void {
    this.store.dispatch(AssetGroupsActions.getAssetGroup({ assetGroupId }));
  }

  addAssetGroup(assetGroup: AssetGroupCreate): void {
    this.store.dispatch(AssetGroupsActions.addAssetGroup({ assetGroup }));
  }

  editAssetGroup(assetGroup: AssetGroup): void {
    this.store.dispatch(AssetGroupsActions.editAssetGroup({ assetGroup }));
  }

  navigateToSite(): void {
    // TODO: REFACTOR SITE OVERVIEW SO IT LOAD ITS DATA WITHOUT RELYING ON INFO-DISPLAY OR NAVIGATION API
    // THEN ACTIVATE THIS LINE OTHERWISE IT WILL BREAK
    // this.router.navigate(['sites', this.selectedAssetGroup()?.siteId]);
    // FOR NOW WE NAVIGATE TO SITES FOR THE PROBLEM MENTIONNED ABOVE
    this.router.navigate(['sites']);
  }

  clickBack(): void {
    // FOR NOW WE NAVIGATE TO SITE, BUT WE SHOULD ALSO BE ABLE TO NAVIGATE ALSO TO ASSET MASTER VIEW
    // WHEN THAT PART IS READY
    this.router.navigate(['sites']);
  }
}
