import { Component, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GridNameCellService } from './grid-name-cell.service';

@Component({
    selector: 'i4b-table-engine-grid-name-cell',
    templateUrl: './grid-name-cell.component.html',
    styleUrls: ['./grid-name-cell.component.scss'],
    standalone: false
})
export class GridNameCellComponent implements OnInit {
  @Input() data: any;
  @Input() rawData: any;
  @Input() columnFormat: any;
  @Input() cellOptions: any;
  @Input() cellInfo: any;

  @Output() dispatchEvent;

  isDataLoaded = false;
  dataToDisplay;
  displayErrorMessage = false;
  errorMessage = 'N/A';

  constructor(private readonly gridNameCellService: GridNameCellService, private readonly translateService: TranslateService) {}

  ngOnInit() {
    if (this.data) {
      this.gridNameCellService.getGridNameById(this.data).subscribe(
        (grid) => {
          this.isDataLoaded = true;
          this.dataToDisplay = grid.name;
        },
        () => {
          this.displayErrorMessage = true;
          this.isDataLoaded = true;
        }
      );
    } else {
      this.isDataLoaded = true;
      this.dataToDisplay = this.translateService.instant('FAVORITE_VIEW.DETAIL.DEFAULT_GRID');
    }
  }
}
