import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { Device } from '@iot-platform/models/i4b';

@Component({
    selector: 'iot4bos-ui-association-device-card',
    templateUrl: './association-device-card.component.html',
    styleUrls: ['../../association-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AssociationDeviceCardComponent {
  @Input() device: Device;
  @Input() navigationMode: boolean;
  @Input() selectedItemId: string;

  @Output() clicked: EventEmitter<Device> = new EventEmitter();

  get imgPath(): string {
    return this.device ? (this.device.imageUrl ? this.device.imageUrl : 'assets/images/device/device_default.png') : 'assets/images/device/device_default.png';
  }

  onClick() {
    this.clicked.emit(this.device);
  }
}
