import { CommonModule } from '@angular/common';
import { Component, DestroyRef, inject, input, model, OnInit, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';

// TODO this component should be removed
@Component({
    imports: [CommonModule],
    selector: 'iot-platform-ui-icon',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.scss']
})
export class IconComponent implements OnInit {
  private matIconRegistry: MatIconRegistry = inject(MatIconRegistry);
  private sanitizer: DomSanitizer = inject(DomSanitizer);
  private destroyRef: DestroyRef = inject(DestroyRef);
  name = input<string>('');
  color = input<string>('');
  isFromRegistry = model<boolean>(false);
  assetIcon: WritableSignal<SafeHtml> = signal('');

  ngOnInit(): void {
    try {
      this.matIconRegistry
        .getNamedSvgIcon(this.name())
        .pipe(
          catchError(() => {
            this.isFromRegistry.set(false);
            return of(null);
          }),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe((result) => {
          if (result) {
            this.isFromRegistry.set(true);
            this.assetIcon.set(this.sanitizer.bypassSecurityTrustHtml(result.outerHTML));
          }
        });
    } catch (error) {
      /* empty */
    }
  }
}
