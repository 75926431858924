import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthorizationConcept, AuthorizationService, AuthorizationType } from '@iot-platform/auth';

import { ProcessMultipleStateVariablesUtil } from '@iot-platform/iot-platform-utils';

import { MasterViewEngineEvent } from '@iot-platform/models/common';
import { get } from 'lodash';

@Component({
    selector: 'i4b-table-engine-rich-variable-value-cell',
    templateUrl: './rich-variable-value-cell.component.html',
    styleUrls: ['./rich-variable-value-cell.component.scss'],
    standalone: false
})
export class RichVariableValueCellComponent implements OnInit {
  @Input() data: any;
  @Input() rawData: any;
  @Input() columnFormat: any;
  @Input() cellOptions: any;
  @Input() clickEvent: any;

  @Output() dispatchEvent: EventEmitter<MasterViewEngineEvent> = new EventEmitter<MasterViewEngineEvent>();

  processedValue: string;
  isMultipleStateVariable: boolean;
  canReadGraph = false;
  minimalDisplay = false;

  constructor(private readonly authorizationService: AuthorizationService) {}

  ngOnInit() {
    this.minimalDisplay = this.cellOptions.minimalDisplay;
    this.canReadGraph = this.authorizationService.applyAuthorization(AuthorizationConcept.GRAPH, AuthorizationType.READ);
    this.setIsMultipleStateVariable();

    if (this.isMultipleStateVariable) {
      this.setProcessedValue();
    }
  }

  setIsMultipleStateVariable() {
    switch (this.cellOptions.dataType) {
      case 'lastRecord':
      case 'lastValue':
        this.isMultipleStateVariable = ProcessMultipleStateVariablesUtil.isUnitMultipleState(this.rawData?.unit);
        break;
      default:
        this.isMultipleStateVariable = ProcessMultipleStateVariablesUtil.isUnitMultipleState(this.data?.unit);
        break;
    }
  }

  setProcessedValue() {
    switch (this.cellOptions.dataType) {
      case 'lastRecord':
      case 'lastValue':
        this.processedValue = ProcessMultipleStateVariablesUtil.getProcessedUnit(this.rawData);
        break;
      default:
        const lastRecord = this.data.value !== null ? [{ value: this.data.value, datetime: '' }] : [];

        this.processedValue = ProcessMultipleStateVariablesUtil.getProcessedUnit({
          ...this.data,
          lastRecords: lastRecord
        });
        break;
    }
  }

  onDispatchEvent(event: MouseEvent): void {
    event.stopPropagation();
    if (!this.minimalDisplay) {
      this.dispatchEvent.emit({ type: this.clickEvent.type, options: this.clickEvent.options, rawData: this.rawData });
    }
  }

  openFollowedVariableEvents(event: MouseEvent) {
    event.stopPropagation();
    this.dispatchEvent.emit({
      type: 'openFollowedVariableEvents',
      options: this.clickEvent.options,
      rawData: { asset: this.rawData, followedVariable: this.data }
    });
  }

  openGraphsByVariable(event: MouseEvent) {
    event.stopPropagation();
    let siteId = get(this.rawData, 'site.id', null);
    if (this.cellOptions.dataType === 'event' || !siteId) {
      siteId = get(this.rawData, 'context.site.id');
    }
    this.dispatchEvent.emit({
      type: 'openGraphsByVariable',
      options: this.clickEvent.options,
      rawData: { asset: this.rawData, followedVariable: this.data, siteId }
    });
  }
}
