import { Component, Input, Output } from '@angular/core';

@Component({
    selector: 'i4b-table-engine-count-cell',
    templateUrl: './count-cell.component.html',
    styleUrls: ['./count-cell.component.scss'],
    standalone: false
})
export class CountCellComponent {
  @Input() data: any;
  @Input() rawData: any;
  @Input() columnFormat: any;

  @Output() dispatchEvent;
}
