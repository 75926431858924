import { UpperCasePipe } from '@angular/common';
import { Component, computed, DestroyRef, inject, Injector, OnInit, signal, Signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AbstractControl, AsyncValidatorFn, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { LocalStorageKeys, LocalStorageService } from '@iot-platform/core';
import { BusinessProfile, FavoriteView, Filter } from '@iot-platform/models/common';
import { GridTypes, I4BGrid, I4BGridData, I4BGridOptions } from '@iot-platform/models/grid-engine';
import { MASTERVIEWS_WITH_FAVORITEVIEW } from '@iot-platform/models/i4b';
import { FavoriteViewsService } from '@iot-platform/shared/components';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ColorPickerModule } from '../color-picker/color-picker.module';
import { DetailPopupModule } from '../detail-popup/detail-popup.module';
import { FilterEngineModule } from '../filter-engine';

interface FavoriteViewFormData {
  favoriteView: FavoriteView;
  canUpdateBusinessProfile: boolean;
  readOnly?: boolean;
  grids?: I4BGrid<I4BGridOptions, I4BGridData>[];
  adminMode?: boolean;
  duplicateMode?: boolean;
  forcedShare?: boolean;
  userId?: string;
}

@Component({
    selector: 'iot-platform-ui-favorite-view-form',
    templateUrl: './favorite-view-form.component.html',
    styleUrls: ['./favorite-view-form.component.scss'],
    imports: [
        MatCardModule,
        MatIconModule,
        MatToolbarModule,
        MatRadioModule,
        MatCheckboxModule,
        MatChipsModule,
        ColorPickerModule,
        TranslateModule,
        FlexLayoutModule,
        MatButtonModule,
        DetailPopupModule,
        MatTabsModule,
        MatSelectModule,
        FilterEngineModule,
        MatProgressSpinnerModule,
        ReactiveFormsModule,
        UpperCasePipe,
        MatInputModule
    ]
})
export class FavoriteViewFormComponent implements OnInit {
  public readonly dialogRef: MatDialogRef<FavoriteViewFormComponent> = inject(MatDialogRef<FavoriteViewFormComponent>);
  private readonly favoriteViewsService: FavoriteViewsService = inject(FavoriteViewsService);
  private readonly translateService: TranslateService = inject(TranslateService);
  private readonly storage: LocalStorageService = inject(LocalStorageService);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  private readonly injector: Injector = inject(Injector);
  public data: FavoriteViewFormData = inject(MAT_DIALOG_DATA);

  form: UntypedFormGroup = new UntypedFormGroup({});

  currentFilters: WritableSignal<Filter[]> = signal(this.data.favoriteView.filters ?? []);
  currentFiltersCount: Signal<number> = computed(() => {
    const filters = this.currentFilters();
    return filters?.filter((filter: Filter) => filter.criteriaKey !== 'includeSubEntities')?.length;
  });

  GridTypes = GridTypes;
  displayGridTab: Signal<boolean> = signal(MASTERVIEWS_WITH_FAVORITEVIEW.includes(this.data.favoriteView?.masterView?.toLowerCase() ?? ''));

  allGrids: WritableSignal<I4BGrid<I4BGridOptions, I4BGridData>[] | undefined> = signal(this.data.grids ?? []);
  userGrids: Signal<I4BGrid<I4BGridOptions, I4BGridData>[]> = computed(() => {
    const allGrids = this.allGrids();
    return allGrids?.filter((grid) => !grid.businessProfileId && !!grid.userId && !grid.isDefault) ?? [];
  });
  sharedGrids: Signal<I4BGrid<I4BGridOptions, I4BGridData>[]> = computed(() => {
    const allGrids = this.allGrids();
    return allGrids?.filter((grid) => !!grid.businessProfileId) ?? [];
  });

  selectedGrid: WritableSignal<I4BGrid<I4BGridOptions, I4BGridData> | null> = signal(null);
  selectedGridIdValueChanges!: Signal<string | undefined>;
  selectedGridTypeValueChanges!: Signal<string | undefined>;
  isSelectedGridIdValid: Signal<boolean> = computed(() => {
    const selectedGridId = this.selectedGridIdValueChanges();
    const selectedGridType = this.selectedGridTypeValueChanges();
    return selectedGridType === GridTypes.DEFAULT_GRID && !selectedGridId ? true : selectedGridType !== GridTypes.DEFAULT_GRID && !!selectedGridId;
  });

  businessProfiles: Signal<BusinessProfile[] | undefined> = this.data.canUpdateBusinessProfile
    ? toSignal(this.favoriteViewsService.getBusinessProfiles().pipe(takeUntilDestroyed(this.destroyRef)))
    : signal([]);

  get name(): AbstractControl {
    return this.form.get('name') as AbstractControl;
  }

  get color(): AbstractControl {
    return this.form.get('color') as AbstractControl;
  }

  get shared(): AbstractControl {
    return this.form.get('shared') as AbstractControl;
  }

  get pinned(): AbstractControl {
    return this.form.get('pinned') as AbstractControl;
  }

  get description(): AbstractControl {
    return this.form.get('description') as AbstractControl;
  }

  get businessProfileId(): AbstractControl {
    return this.form?.get('businessProfileId') as AbstractControl;
  }

  get filters(): AbstractControl {
    return this.form?.get('filters') as AbstractControl;
  }

  get selectedGridType(): AbstractControl {
    return this.form?.get('selectedGridType') as AbstractControl;
  }

  get selectedGridId(): AbstractControl {
    return this.form?.get('selectedGridId') as AbstractControl;
  }

  ngOnInit(): void {
    this.initForm();
    this.initListeners();
    this.initGrids();
    this.setSelectedGrid();
  }

  private initGrids() {
    if (!this.data.grids && this.data.favoriteView.masterView) {
      this.setAllGrids(this.data.favoriteView?.masterView, this.businessProfileId.getRawValue() as string, this.data.userId as string);
    }
  }

  onApplyFilters(filters: Filter[]): void {
    this.filters.patchValue(filters);
    this.currentFilters.set(filters);
  }

  onBusinessProfileChange(businessProfileId: string): void {
    this.setAllGrids(this.data.favoriteView?.masterView as string, businessProfileId, this.data.userId as string);
    this.name.updateValueAndValidity({ onlySelf: true, emitEvent: true });
  }

  onGridTypeChange(): void {
    this.selectedGridId.patchValue(null);
    this.selectedGrid.set(null);
  }

  onSelectedGridChange(grid: I4BGrid<I4BGridOptions, I4BGridData>): void {
    this.selectedGridId.patchValue(grid.id);
    this.selectedGrid.set(grid);
  }

  setSelectedGrid(): void {
    const { gridId } = this.data.favoriteView;
    const selectedGrid = this.sharedGrids().find((grid) => grid.id === gridId) || this.userGrids().find((grid) => grid.id === gridId);

    if (!gridId || !selectedGrid) {
      this.selectedGridType.patchValue(GridTypes.DEFAULT_GRID);
      this.selectedGridId.patchValue(null);
      this.selectedGrid.set(null);
    } else {
      const gridType = this.sharedGrids().some((grid) => grid.id === selectedGrid.id) ? GridTypes.SHARED_GRIDS : GridTypes.USER_GRID;
      this.selectedGridType.patchValue(gridType);
      this.selectedGridId.patchValue(gridId);
      this.selectedGrid.set(selectedGrid);
    }
  }

  save(): void {
    this.dialogRef.close({
      grid:
        this.data.duplicateMode || this.data.adminMode || (this.selectedGridType.getRawValue() === GridTypes.USER_GRID && this.shared.getRawValue())
          ? this.selectedGrid()
          : null,
      favoriteView: {
        ...(!this.data.duplicateMode && !this.data.adminMode ? this.data.favoriteView : {}),
        name: this.name.getRawValue().trim(),
        color: this.color.getRawValue(),
        shared: this.shared.getRawValue(),
        pinned: this.pinned.getRawValue(),
        description: this.description.getRawValue(),
        businessProfileId: this.businessProfileId.getRawValue(),
        filters: this.filters.getRawValue(),
        gridId: this.selectedGridId.getRawValue(),
        ...(this.data.duplicateMode || this.data.adminMode
          ? {
              concept: this.data.favoriteView.concept,
              masterView: this.data.favoriteView.masterView
            }
          : {})
      }
    });
  }

  private setAllGrids(masterview: string, businessProfileId: string, userId: string): void {
    this.favoriteViewsService
      .getGrids(masterview, businessProfileId, userId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((grids) => {
        this.allGrids.set(grids);
      });
  }

  private initForm(): void {
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(
        { value: this.getNameForFavoriteViewForm(), disabled: !!this.data.readOnly },
        {
          validators: [Validators.required, Validators.maxLength(50)],
          asyncValidators: [this.favoriteViewUniqueNameValidator()]
        }
      ),
      color: new UntypedFormControl({ value: this.data.favoriteView.color ?? '', disabled: !!this.data.readOnly }, [Validators.required]),
      shared: new UntypedFormControl({
        value: (this.data.favoriteView.shared || this.data.forcedShare) ?? false,
        disabled: (this.data.readOnly || this.data.favoriteView.shared || this.data.forcedShare) ?? false
      }),
      pinned: new UntypedFormControl({ value: this.data.favoriteView.pinned ?? false, disabled: this.data.readOnly || this.data.duplicateMode }),
      businessProfileId: new UntypedFormControl(
        {
          value: this.data.favoriteView.businessProfileId ?? JSON.parse(this.storage.get(LocalStorageKeys.STORAGE_BUSINESS_PROFILE_KEY)).id,
          disabled: !this.data.adminMode && !this.data.duplicateMode
        },
        [Validators.required]
      ),
      description: new UntypedFormControl(this.data.favoriteView.description ?? '', [Validators.maxLength(200)]),
      filters: new UntypedFormControl({ value: this.data.favoriteView.filters ?? [], disabled: this.data.duplicateMode }, [
        Validators.required,
        Validators.minLength(1)
      ]),
      selectedGridType: new UntypedFormControl({ value: GridTypes.DEFAULT_GRID, disabled: this.data.duplicateMode }),
      selectedGridId: new UntypedFormControl({ value: null, disabled: this.data.duplicateMode })
    });
  }

  private getNameForFavoriteViewForm(): string {
    return this.data.favoriteView.name
      ? this.data.duplicateMode
        ? this.translateService.instant('FAVORITE_VIEW.FORM.DUPLICATE_NAME', { favoriteViewName: this.data.favoriteView.name.trim() })
        : this.data.favoriteView.name.trim()
      : '';
  }

  private initListeners(): void {
    this.selectedGridIdValueChanges = toSignal(this.selectedGridId.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)), {
      injector: this.injector
    });
    this.selectedGridTypeValueChanges = toSignal(this.selectedGridType.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)), {
      injector: this.injector
    });
  }

  private favoriteViewUniqueNameValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      const name = control?.getRawValue().trim() as string;
      const businessProfileId = this.businessProfileId?.getRawValue() as string;
      const shared = this.shared?.getRawValue() as boolean;
      const initialName = this.data.favoriteView.name ?? '';
      const masterview = this.data?.favoriteView?.masterView as string;

      if (name) {
        if (name.toLowerCase() === initialName?.toLowerCase()) {
          return of(null);
        }

        return this.favoriteViewsService
          .isNameUnique(name, businessProfileId, masterview, shared)
          .pipe(map((unique: boolean) => (unique ? null : { nameExists: true })));
      }
      return of(null);
    };
  }
}
