import { Component, Input, Output } from '@angular/core';

@Component({
    selector: 'i4b-table-engine-value-to-icon-cell',
    templateUrl: './value-to-icon-cell.component.html',
    styleUrls: ['./value-to-icon-cell.component.scss'],
    standalone: false
})
export class ValueToIconCellComponent {
  @Input() data: any;
  @Input() rawData: any;
  @Input() columnFormat: any;
  @Input() cellOptions: ValueToIconConfig;

  @Output() dispatchEvent;

  getIcon(): string {
    return this.cellOptions.options.find((option) => option.value === this.data)?.icon;
  }
}

interface ValueToIconConfig {
  options: [{ value: string; icon: string }];
  iconSize: number;
}
