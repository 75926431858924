import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component, computed, output, Signal, signal, WritableSignal } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { SortUtil } from '@iot-platform/iot-platform-utils';
import { MasterViewEngineEvent } from '@iot-platform/models/common';

import { CallToActionCellParams } from '../../../models/call-to-action-cell.params';
import { CallToActionMenuItemComponent } from './call-to-action-menu-item/call-to-action-menu-item.component';

@Component({
    selector: 'grid-engine-call-to-action-cell',
    templateUrl: './call-to-action-cell.component.html',
    imports: [FlexLayoutModule, MatIcon, MatMenu, CallToActionMenuItemComponent, MatIconButton, MatMenuTrigger],
    styleUrls: ['./call-to-action-cell.component.scss']
})
export class CallToActionCellComponent implements ICellRendererAngularComp {
  params: WritableSignal<CallToActionCellParams> = signal(null);

  dispatchEvent = output<MasterViewEngineEvent>();

  actions: Signal<any[]> = computed(() => {
    const params = this.params();
    return params?.actions?.sort(SortUtil.sortByOrder);
  });

  agInit(params: CallToActionCellParams): void {
    this.params.set(params);
  }

  refresh(): boolean {
    return false;
  }

  onActionClicked(event: MasterViewEngineEvent) {
    const params = this.params();
    params.dispatchEvent(event);
  }
}
