import { NgClass } from '@angular/common';
import { Component, input } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatRippleModule } from '@angular/material/core';
import { MatIcon } from '@angular/material/icon';

@Component({
    imports: [MatIcon, NgClass, FlexLayoutModule, MatRippleModule],
    selector: 'iot-platform-ui-default-card',
    templateUrl: './default-card.component.html',
    styleUrls: ['./default-card.component.scss']
})
export class DefaultCardComponent {
  title = input<string>();
  backgroundClass = input<string>();
  withCustomButton = input<boolean>(false);
}
