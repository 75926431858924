import { UpperCasePipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { SortUtil } from '@iot-platform/iot-platform-utils';

import { BusinessProfile, Role } from '@iot-platform/models/common';

import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';

import { BusinessProfilesService } from '../../../features/admin-business-profiles/services/business-profiles.service';
import { BusinessProfilesRolesDialogActions } from '../../../features/admin-business-profiles/state/actions';
import * as fromProfiles from '../../../features/admin-business-profiles/state/reducers';

@Component({
    imports: [
        FlexLayoutModule,
        MatCardModule,
        MatToolbarModule,
        MatIcon,
        MatListModule,
        MatFormFieldModule,
        MatButtonModule,
        TranslateModule,
        UpperCasePipe,
        MatProgressSpinner
    ],
    selector: 'iot4bos-ui-backoffice-manage-business-profile-roles-dialog',
    templateUrl: './manage-business-profile-roles-dialog.component.html',
    styleUrls: ['./manage-business-profile-roles-dialog.component.scss', '../../../style/admin.style.scss']
})
export class ManageBusinessProfileRolesDialogComponent {
  public data: { businessProfile: BusinessProfile } = inject(MAT_DIALOG_DATA);
  allRoles: Role[] = [];
  unselectedRoles: Role[] = [];
  currentRoles: Role[] = [];
  param = { bPName: '' };
  unselectedRolesPending = true;
  private dialogRef: MatDialogRef<ManageBusinessProfileRolesDialogComponent> = inject(MatDialogRef<ManageBusinessProfileRolesDialogComponent>);
  private businessProfileService: BusinessProfilesService = inject(BusinessProfilesService);
  private store: Store = inject(Store);

  constructor() {
    this.store.select(fromProfiles.getAllRolesByBusinessProfile).subscribe((roles: Role[]) => {
      this.currentRoles = roles;
      this.unselectedRoles = this.checkAvailableRoles(this.allRoles).sort(SortUtil.sortByName);
    });

    this.businessProfileService.getRolesByOrganization(this.data.businessProfile.entityId).subscribe((rolesByOrganization: Role[]) => {
      this.allRoles = rolesByOrganization;
      this.unselectedRoles = [...this.checkAvailableRoles(rolesByOrganization)].sort(SortUtil.sortByName);
      this.unselectedRolesPending = false;
    });
  }

  removeRoleFromProfile(roleToRemove: Role) {
    this.store.dispatch(
      BusinessProfilesRolesDialogActions.removeRoleFromBusinessProfile({
        businessProfileId: this.data.businessProfile.id,
        roleToRemove
      })
    );
  }

  addRoleToProfile(roleToAdd: Role) {
    this.store.dispatch(
      BusinessProfilesRolesDialogActions.addRoleToBusinessProfile({
        businessProfileId: this.data.businessProfile.id,
        roleToAdd
      })
    );
  }

  exit() {
    this.dialogRef.close(true);
  }

  private checkAvailableRoles(rolesByOrganization: Role[]): Role[] {
    const available: Role[] = [];
    rolesByOrganization.forEach((role) => {
      if (this.currentRoles.indexOf(this.currentRoles.find((r) => role.id === r.id)) === -1) {
        available.push(role);
      }
    });
    return available;
  }
}
