import { Component, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { fromAuth } from '@iot-platform/auth';

import { UserAccount } from '@iot-platform/models/common';

import { Store } from '@ngrx/store';

import { combineLatest, Subscription } from 'rxjs';

import { TopicSelfSubscriptionCellService } from './topic-self-subscription-cell.service';

@Component({
    selector: 'i4b-table-engine-topic-self-subscription-cell',
    templateUrl: './topic-self-subscription-cell.component.html',
    styleUrls: ['./topic-self-subscription-cell.component.scss'],
    standalone: false
})
export class TopicSelfSubscriptionCellComponent implements OnInit, OnDestroy {
  @Input() data: any;
  @Input() rawData: any;
  @Input() columnFormat: any;
  @Input() cellOptions: any;
  @Input() cellInfo: any;

  @Output() dispatchEvent;

  subscribersLoaded = false;
  dataToDisplay;

  subscriptions: Subscription[] = [];

  constructor(
    private store: Store,
    private topicSelfSubscriptionService: TopicSelfSubscriptionCellService
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(
      combineLatest([this.store.select(fromAuth.selectCurrentUser), this.topicSelfSubscriptionService.getSubscribersByTopic(this.rawData)]).subscribe(
        ([currentUser, subscribers]) => {
          this.subscribersLoaded = true;
          if (currentUser && subscribers) {
            this.checkCurrentUserSubscription(currentUser, subscribers);
          }
        },
        () => (this.subscribersLoaded = true)
      )
    );
  }

  checkCurrentUserSubscription(currentUser: UserAccount, subscribers: string[]): void {
    this.dataToDisplay = subscribers.find((userId) => userId === currentUser.id) ? 'TOPICS.MASTER_VIEW.SUBSCRIBED' : null;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
