import { Component, Input, Output } from '@angular/core';

@Component({
    selector: 'i4b-table-engine-icon-and-text-header',
    templateUrl: './icon-and-text-header.component.html',
    styleUrls: ['./icon-and-text-header.component.scss'],
    standalone: false
})
export class IconAndTextHeaderComponent {
  @Input() data: any;
  @Input() rawData: any;
  @Input() columnFormat: any;

  @Output() dispatchEvent;
}
