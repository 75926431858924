import { Component, Input, Output } from '@angular/core';

@Component({
    selector: 'i4b-table-engine-last-command-status-cell',
    templateUrl: './last-command-status-cell.component.html',
    styleUrls: ['./last-command-status-cell.component.scss'],
    standalone: false
})
export class LastCommandStatusCellComponent {
  @Input() data: any;
  @Input() rawData: any;
  @Input() columnFormat: any;
  @Input() cellOptions: any;

  @Output() dispatchEvent;
}
