import { Component, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'i4b-table-engine-array-of-objects-cell',
    templateUrl: './array-of-objects-cell.component.html',
    styleUrls: ['./array-of-objects-cell.component.scss'],
    standalone: false
})
export class ArrayOfObjectsCellComponent implements OnInit {
  @Input() data: any[];
  @Input() rawData: any;
  @Input() columnFormat: any;
  @Input() cellOptions: any;
  @Input() cellInfo: any;

  @Output() dispatchEvent;

  dataToDisplay;

  ngOnInit(): void {
    this.dataToDisplay = this.data.map((data) => (this.cellOptions && this.cellOptions.attribute ? data[this.cellOptions.attribute] : data)).join(', ');
  }
}
